import http from '@/utils/axios'
const api = {
  query: (data) => http.get('/om/ticket/page', data),
  add: (data) => http.post('/om/ticket/add', data),
  modify: (data) => http.put('/om/ticket/update', data),
  close: ({ id }) => http.put(`/om/ticket/close?id=${id}`),
  reply: (data) => http.post('/om/ticket/reply', data),
  detail: (data) => http.get('/om/ticket/detail', data),
  getAdviceOrder: (data) => http.get('/appstore/ticket/back/page', data),
  updateAdviceOrder: (data) => http.put('/appstore/ticket/update', data),
}
export default api
