<template>
  <el-dialog title="工单回复" width="600px" :visible="show" @close="close" :close-on-click-modal="false">
    <div v-if="recordData && recordData.length" class="mb20">
      <div class="common-header">回复记录</div>
      <ul>
        <li v-for="(item, index) in recordData" :key="index" class="flex-cc mb10">
          <div>{{ item.type == 0 ? '提问：' : '回复：' }}</div>
          <div class="flex-1">{{ item.content }}</div>
          <div class="font12" style="color: #999">{{ item.createTime }}</div>
        </li>
      </ul>
    </div>
    <div v-if="detail.status != 2">
      <div class="common-header">工单回复</div>
      <!-- <w-wang-editor :value="content" @change="changeEditor" /> -->
      <el-input type="textarea" v-model="content" rows="6" placeholder="请输入" clearable />
    </div>
    <div slot="footer" >
      <el-button @click="close" v-if="detail.status != 2">取消</el-button>
      <el-button type="primary" @click="reply" v-if="detail.status != 2">确定</el-button>
      <el-button @click="close" v-else>关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api/operate/order'
import WWangEditor from '@/components/wangEditor/index'
export default {
  components: { WWangEditor },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      content: '',
      recordData: [],
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.getDetail()
      } else {
        this.recordData = []
        this.content = ''
      }
    },
  },
  methods: {
    getDetail() {
      api.detail({ ticketId: this.detail.ticketId }).then((res) => {
        this.recordData = res.data.details
      })
    },
    close() {
      this.$emit('update:show', false)
    },
    changeEditor(val) {
      this.content = val
    },
    reply() {
      let params = {
        type: 1,
        ticketId: this.detail.ticketId,
        content: this.content,
      }
      api.reply(params).then(() => {
        this.$msg.success('操作成功')
        this.close()
      })
    },
  },
}
</script>
